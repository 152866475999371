import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Heading, IconClock, IconDuration, IconPerson, IconVolume } from '../../components';
import groupBy from 'lodash/groupBy';
import CategoryAccordion from './CategoryAccordion/CategoryAccordion';
import { formatMoney } from '../../util/currency';

import css from './ListingPage.module.css';

const Service = ({ service, type, onSelectService, selectedService }) => {
  const [isExtended, setIsExtended] = useState(false);

  const {
    title,
    [`pub_sub_category_${type}`]: category,
    price,
    addons,
    additionalGuests,
    appointmentDuration,
    description,
    pricingType,
    features,
    minimumHours,
    pub_high_volume_allowed: isHighVolumeAllowed,
    id,
  } = service;

  const intl = useIntl();

  const additionalGuestsMaybe = additionalGuests ? (
    <div className={css.serviceDetails}>
      <IconPerson className={css.serviceIcon} />
      <FormattedMessage
        id="ListingPage.SectionServices.additionalGuests"
        values={{ min: additionalGuests[0], max: additionalGuests[1] }}
      />
    </div>
  ) : null;
  const appointmentDurationMaybe = appointmentDuration ? (
    <div className={css.serviceDetails}>
      <IconClock className={css.serviceIcon} />
      <FormattedMessage
        id="ListingPage.SectionServices.appoinment.duration"
        values={{ appointmentDuration: +appointmentDuration }}
      />
    </div>
  ) : null;
  const addonsMaybe = addons?.length ? <></> : null;
  const minimumHoursMaybe = minimumHours ? (
    <div className={css.serviceDetails}>
      <IconDuration className={css.serviceIcon} />
      <FormattedMessage
        id="ListingPage.SectionServices.minimumHours"
        values={{ minimumHours: +minimumHours }}
      />
    </div>
  ) : null;
  const highVolumeAllowedMaybe =
    type === 'music' && isHighVolumeAllowed ? (
      <div className={css.serviceDetails}>
        <IconVolume className={css.serviceIcon} isOn={isHighVolumeAllowed} />
        <FormattedMessage
          id="ListingPage.SectionServices.music.highVolumeAllowed"
          values={{ isHighVolumeAllowed }}
        />
      </div>
    ) : null;
  const featuresMaybe = features?.length ? (
    <ul>
      {features.map(feature => (
        <li>{feature}</li>
      ))}
    </ul>
  ) : null;

  const isThisServiceSelected = selectedService?.id === id;

  return (
    <div className={css.serviceCard}>
      <div className={css.serviceCardHeader}>
        <h3 className={css.serviceTitle}>{title}</h3>
        <div className={css.servicePricingInfo}>
          <p className={css.servicePrice}>{formatMoney(intl, price)}</p>
          <span className={css.servicePricingType}>
            {intl.formatMessage(
              {
                id: 'ListingPage.SectionServices.pricingType',
              },
              {
                pricingType,
              }
            )}
          </span>
        </div>
      </div>
      <p className={css.serviceDescription}>{description}</p>
      <div className={css.serviceContent}>
        <div className={css.serviceInfo}>
          {additionalGuestsMaybe}
          {appointmentDurationMaybe}
          {addonsMaybe}
          {minimumHoursMaybe}
          {highVolumeAllowedMaybe}
          {featuresMaybe}
        </div>
        <Button
          className={css.serviceBookBtn}
          onClick={() => onSelectService(service)}
          disabled={isThisServiceSelected}
        >
          {intl.formatMessage(
            { id: 'ListingPage.SectionServices.button.book' },
            { isThisServiceSelected }
          )}
        </Button>
      </div>
    </div>
  );
};

const ServiceGroup = ({ type, services, onSelectService, selectedService }) => {
  const title = (
    <h3 className={css.serviceGroupTitle}>
      <FormattedMessage
        id="ListingPage.SectionServices.serviceType"
        values={{ type, numOfServices: services?.length ?? 0 }}
      />
    </h3>
  );
  return (
    <>
      {title}
      <div className={css.serviceList}>
        {services.map(service => (
          <Service
            key={service.id}
            service={service}
            type={type}
            onSelectService={onSelectService}
            selectedService={selectedService}
          />
        ))}
      </div>
    </>
  );
};

const SectionServices = props => {
  const { publicData, onSelectService, selectedService } = props;

  if (!publicData) {
    return null;
  }

  const { services } = publicData;

  const serviceGroups = useMemo(
    () => Object.entries(groupBy(services, service => service.serviceType)),
    [services]
  );

  const totalServices = useMemo(
    () => serviceGroups.reduce((acc, [_, services]) => acc + (services?.length || 0), 0),
    [serviceGroups]
  );

  return services.length > 0 ? (
    <section className={css.sectionServices}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage
          id="ListingPage.SectionServices.servicesTitle"
          values={{ totalServices }}
        />
      </Heading>
      <div className={css.services}>
        {serviceGroups.map(([type, services]) => (
          <ServiceGroup
            type={type}
            services={services}
            key={type}
            onSelectService={onSelectService}
            selectedService={selectedService}
          />
        ))}
      </div>
    </section>
  ) : null;
};

export default SectionServices;
