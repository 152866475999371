import React from 'react';
import classNames from 'classnames';
import { bool, node, string } from 'prop-types';

import css from './CategoryAccordion.module.css';

const CategoryAccordion = props => {
  const { id, className, rootClassName, title, children } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <input id={id} className={css.accordionTriggerInput} type="checkbox"></input>
      <label className={css.accordionTrigger} for={id}>
        {title}
      </label>
      <section className={css.accordionAnimationWrapper}>
        <div className={css.accordionAnimation}>
          <div className={css.accordionTransformWrapper}>
            <div className={css.accordionContent}>{children}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

CategoryAccordion.propTypes = {
  id: string,
  children: node.isRequired,
  title: string,
  isActive: bool,
};

CategoryAccordion.defaultProps = {
  isActive: false,
};

export default CategoryAccordion;
